@import 'styles/variables';

$browser-context: 14;

.data-picker {
  font-size: 14px;

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__input-container {
    &::before {
      position: absolute;
      right: 10px;
      top: 48%;
      transform: translateY(-50%);
      content: '';
      width: 24px;
      height: 24px;
      color: #7a7a99;
      background-image: url('./calendar-bg.svg');
      background-size: cover;
      pointer-events: none;
      z-index: 5;
    }
  }

  input {
  }

  &.error {
    input {
      border: 1px solid $color-danger;
      color: $color-danger;

      &:hover,
      &:focus {
        border-color: $color-danger;
      }
    }
  }

  // .react-datepicker__triangle {
  //   display: none;
  // }

  .react-datepicker-popper {
    z-index: 25;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    // margin-top: 5px;
    padding-top: 5px;
  }

  .react-datepicker {
    border-radius: 4px;
    background: #ffffff;
    border: none;
    font-size: inherit;
    font-family: inherit;
    @include shadow1();
  }

  .react-datepicker__navigation {
    top: 4px;
    &:focus {
      outline: none;
    }
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 1px solid #e8e8e8;
  }

  .date-picker-header {
    color: #262626;
    font-size: 1em;
    font-weight: 500;
    line-height: 22px;
    height: 22px;
    position: relative;
    user-select: none;

    .date-picker-header__button {
      height: 100%;
      width: 32px;
      color: #ccc;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;

      &:focus,
      &:hover {
        outline: none;
        color: #9d9d9d;
      }
    }

    .date-picker-header__icon {
      height: 16px;
      width: 16px;
    }

    .date-picker-header__monthLeft {
      left: 32px;
    }

    .date-picker-header__monthRight {
      right: 32px;
    }

    .date-picker-header__yearLeft {
      left: 0;
    }

    .date-picker-header__yearRight {
      right: 0;
    }

    .date-picker-header__yearLeft,
    .date-picker-header__yearRight {
      color: #bdbdbd;

      &:focus,
      &:hover {
        outline: none;
        color: #8a8a8a;
      }

      .date-picker-header__icon {
        height: 14px;
        width: 14px;
      }
    }
  }

  .react-datepicker__current-month {
    color: #262626;
    font-size: 1em;
    font-weight: 500;
    line-height: em(22);
  }

  .react-datepicker__day-name {
    color: #262626;
    font-size: 1em;
    line-height: em(22);
    font-weight: 400;
    width: em(24);
    height: em(24);
    margin: #{em(3)} #{em(6)};
    opacity: 0.7;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .react-datepicker__day {
    color: #595959;
    font-size: 1em;
    line-height: em(26);
    font-weight: 400;
    width: em(32);
    height: em(26);
    border-radius: 0;
    margin: #{em(2)} #{em(2)};
    transition: box-shadow 0.3s;

    &:hover {
      background: #f1f0ff;
    }
  }

  .react-datepicker__day--outside-month,
  .react-datepicker__day--disabled {
    color: #bfbfbf;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: #f1f0ff;
    box-shadow: 0 0 0 1px #282781;
  }

  // стили для выбора месяца
  .react-datepicker-year-header {
    color: #262626;
    font-size: 1em;
    font-weight: 500;
    line-height: em(22);
    padding-bottom: 4px;
    padding-top: 9px;
  }

  .react-datepicker__month-text {
    color: #595959;
    font-size: 1em;
    line-height: em(26);
    font-weight: 400;
    width: em(80);
    height: em(26);
    border-radius: 0;
    margin: #{em(2)} #{em(2)};
    transition: box-shadow 0.3s;

    &:hover {
      background: #f1f0ff;
    }
  }

  .react-datepicker__month--selected,
  .react-datepicker__month-text--keyboard-selected {
    background: #f1f0ff;
    box-shadow: 0 0 0 1px #282781;
  }

  .react-datepicker__month--disabled {
    color: $color-gray-5;
  }

  // стили для года
  .react-datepicker__year-wrapper {
    max-width: 252px;
  }

  .react-datepicker__year-text {
    color: #595959;
    font-size: 1em;
    line-height: em(26);
    font-weight: 400;
    width: em(80);
    height: em(26);
    border-radius: 0;
    margin: #{em(2)} #{em(2)};
    transition: box-shadow 0.3s;

    &:hover {
      background: #f1f0ff;
    }
  }

  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__year-text--selected {
    background: #f1f0ff;
    box-shadow: 0 0 0 1px #282781;
  }

  .react-datepicker__year-text--disabled {
    color: $color-gray-5;
  }

  // .react-datepicker__year-text--today {
  //   font-weight: 500;
  // }
}

.data-picker--T1 {
  .react-datepicker__input-container {
    &::before {
      right: 10px;
      top: 48%;
      width: 24px;
      height: 24px;
      color: #7a7a99;
      background-image: url('./calendar-bg.svg');
    }
  }

  input {
    @include input();
    width: 100%;
  }

  &.hasValue {
    input {
      background: $color-light-purple;
    }
  }
}

.data-picker--T2 {
  .react-datepicker__input-container {
    &::before {
      right: 10px;
      top: 48%;
      width: 24px;
      height: 24px;
      color: red;
      background-image: url('./calendar-t2.svg');
    }
  }

  input {
    @include input-t2();
    width: 100%;
  }

  &.data-picker--large {
    input {
      height: 64px;
    }
  }

  &.data-picker--mui {
    input {
      height: 64px;
      padding-top: 20px;
    }
    .date-picker-mui-label {
      position: absolute;
      z-index: 5;
      transform: translateY(-50%);
      left: 12px;
      top: 50%;
      color: rgba(26, 34, 59, 0.7);
      font-size: 16px;
      cursor: text;
      transition: all 0.15s ease-in;
      pointer-events: none;
    }

    &:focus-within .date-picker-mui-label,
    input:focus + .date-picker-mui-label,
    input.date-picker-input--hasValue + .date-picker-mui-label,
    input:-webkit-autofill + .date-picker-mui-label {
      top: 18px;
      font-size: 13px;
    }
  }

  &.productTableInput {
    font-size: 13px;
    .react-datepicker__input-container {
      &::before {
        width: 20px;
        height: 20px;
      }
    }

    input {
      min-width: auto;
      border-radius: 0;
      border-color: transparent;
      font-size: 14px;
      line-height: 19px;
      height: 29px;
      padding-left: 9px;
      padding-right: 9px;

      &:read-only {
        background: #f6f7fc;
      }

      &.error {
        background: transparentize($color: $color-danger, $amount: 0.95);
        color: #1a223b;
      }
    }

    .react-datepicker-popper:has(.react-datepicker__time-container) {
      width: 352px;
    }
  }
}
