@import 'styles/variables';

.wrapper {
  position: relative;
  .options {
    position: absolute;
    @include shadow1();
    border-radius: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
    z-index: 25;
    width: var(--menu-width, 100%);
    width: 100%;
    @include custom-scroll();
    background-color: white;
    list-style: none;
    padding: 0;
    max-height: 230px;
    overflow: auto;
  }

  .option {
    min-height: 32px;
    background-color: transparent;
    font-size: 14px;
    line-height: 24px;
    color: #595959;
    padding: 4px 14px;
    margin-bottom: 0;
    width: var(--menu-width);
    cursor: pointer;

    &:hover {
      background: #f5f5f5;
    }

    &.optionDisabled {
      color: $color-gray-6;
      cursor: not-allowed;
    }

    &.loading {
      color: $color-gray-7;
      display: flex;
      justify-content: center;
      padding: 8px 14px;
    }
  }
}
