@import 'styles/variables';

.wrapper {
  @include container();
  padding-top: 20px;

  .inner {
    margin-top: 24px;
    padding-left: 16px;
  }

  .goodAmount {
    margin: 32px 0 8px;
  }
}
