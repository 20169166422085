@import 'styles/variables';

.wrapper {
  padding: 32px 40px;
  background: url('../../assets/mounts_w2.png') $color-surface-1;
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 800px;
  min-height: calc(100vh - #{$taxpayer-header-height});

  .topRow {
  }

  .grid {
    display: grid;
    grid-template-columns: auto 356px;
    grid-template-rows: auto;
    align-items: start;
    gap: 0 20px;
    margin-top: 24px;
  }

  .boards {
    background-color: #fff;
    box-shadow: 0px 5px 20px 0px rgba(50, 40, 137, 0.05);

    border-radius: 10px;

    .board:not(:last-child) {
      border-bottom: 1px solid #efefef;
    }

    .boardHeader {
      padding: 20px;
      height: 70px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .boardArrowIcon {
      color: #d9d9d9;
      width: 15px;
      height: 12px;
      margin: 2px 0 0 auto;
    }

    .boardIcon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: gray;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        color: white;
      }
    }

    .boardEsf {
      .boardIcon {
        background: linear-gradient(135deg, #3642ff 0%, #ee26ff 100%);
      }
    }

    .boardSnt {
      .boardIcon {
        background: linear-gradient(135deg, #b819ff 0%, #ff1995 100%);
      }
    }

    .boardAwp {
      .boardIcon {
        background: linear-gradient(135deg, #ff6b00 0%, #ffa800 100%);
      }
    }

    .boardEcontract {
      .boardIcon {
        background: linear-gradient(135deg, #ffd800 0%, #ffb800 100%);
      }
    }

    :global(.statusColorYellow) {
      color: $color-yellow-6;
    }
    :global(.statusColorGreen) {
      color: $color-green-7;
    }
    :global(.statusColorBlack) {
      color: $color-text-2;
    }
    :global(.statusColorGray) {
      color: transparentize($color-text-2, 0.55);
    }
    :global(.statusColorRed) {
      color: $color-red-7;
    }
  }

  .toJournalButton {
    height: 32px;
    font-size: 14px;
  }

  .aside {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    margin-left: 12px;
    color: $color-text-2;
  }

  .linksRow {
    margin-bottom: 22px;
    display: flex;
  }

  .oldVersionLink {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    margin-left: auto;
    margin-right: 24px;
    &:visited {
      color: rgba(0, 0, 0, 0.8);
    }
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
