@import 'styles/variables';

.wrapper {
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: $color-gray-5;
  text-align: center;
}

.color-YELLOW {
  background-color: $color-yellow-5;
}

.color-GREEN {
  background-color: $color-green-5;
}

.color-BLUE {
  background-color: $color-blue-5;
}

.color-RED {
  background-color: $color-red-5;
}

.color-TRANSPARENT {
  background-color: transparent;
  border: 1px solid $color-gray-5;
}
