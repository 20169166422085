@import 'styles/variables';

.wrapper {
  position: relative;
  .header {
    display: flex;
    margin-bottom: 16px;
  }

  .button + .button {
    margin-left: 8px;
  }

  .scrollContainer {
    width: 100%;
    min-width: 600px;
    overflow: auto;
    cursor: grab;
    padding-bottom: 24px;
  }
}
