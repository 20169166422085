@import 'styles/variables';

.wrapper {
  background: url('../../../assets/hills.png'), $color-primary;
  background-position: bottom;
  background-repeat: repeat-x;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  min-height: 100%;
  min-width: 1440px;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  .content {
    flex: 1 0 auto;
    display: flex;
    padding-left: $nav-aside-max;
    padding-right: 12px;
    width: 100%;
    @include transition(padding, color);
  }

  .aside {
    flex-shrink: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: $nav-aside-max;
    @include transition(width);
    z-index: 200;
  }

  .navToggle {
    background-color: #2c2653;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translateX(50%);
    right: 0;
    top: 40px;
    color: #fff;
    z-index: $z-navToggle;
    cursor: pointer;

    svg {
      position: absolute;
      transform: translate(-50%, -50%) rotate(180deg);
      top: 50%;
      left: 50%;
    }
  }

  .innerContainer {
    position: relative;
    background-color: #fff;
    flex: 0 0 100%;
    border-radius: 6px;
    color: $color-text;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    padding-bottom: 24px;
    width: 100%;
  }

  .header {
    height: 80px;
    border-bottom: 1px solid #d9d9d9;
  }

  .footer {
    flex-shrink: 0;
    height: 60px;
    padding-right: 12px;

    a {
      color: #fff;
      font-weight: 300;
      &:hover {
        color: #ddd;
      }
    }
  }

  &.minimized {
    .content {
      padding-left: $nav-aside-min;
    }
    .aside {
      width: $nav-aside-min;
    }
    .navToggle {
      svg {
        left: 52%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
