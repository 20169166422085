@import 'styles/variables';

.wrapper {
  padding-top: 24px;
  max-width: 1000px;

  .title {
    margin-bottom: 16px;
  }

  .text {
    margin: 16px 0;
  }

  .container-T1 {
    @include container();
  }

  .container-T2 {
    @include container-t2();
  }
}
