@import 'styles/variables';

h1.heading {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
h2.heading {
  font-size: 30px;
  line-height: 38px;
  font-weight: 500;
}
h3.heading {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}
h4.heading {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.noMargin {
  margin: 0;
}

h2.T2 {
  font-size: 36px;
  line-height: 38px;
  font-weight: 700;
}
h3.T2 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
