@import 'styles/variables';

.wrapper {
  .container {
    display: flex;
  }

  .submit {
    margin-top: 8px;
  }

  &.modal {
    .column {
      @include modal-container();
    }
  }

  .permissionGroupLabel {
    font-weight: 700;
  }

  .permissionGroupLabel:not(:first-child) {
    margin: 20px 0 4px;
  }

  .permissionField {
    margin: 8px 0;
  }
}

.column {
  padding-top: 20px;
  padding-bottom: 24px;
  width: 500px;

  &:not(:first-child) {
    border-left: 1px solid $color-gray-4;
  }
}
