@import 'styles/variables';

.wrapper {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #262626;
  cursor: pointer;
  margin: 0 0 2px;
}

.inline {
  margin: 0 8px 0 0;
}

.reverse {
  order: 2;
  padding-left: 8px;
  margin: 0;
}

.readOnly {
  // color: $color-gray-6;
  cursor: auto;
}

.required {
  position: relative;
  &::before {
    content: '*';
    font-size: 17px;
    font-weight: bold;
    position: absolute;
    top: -2px;
    left: -12px;
    color: $color-red-5;
  }
}

.T2 {
  font-size: 16px;
  line-height: 22px;
  color: $color-text-2;
}
