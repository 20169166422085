@import 'styles/variables';

.wrapper {
  @include modal-container();
  height: 56px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding-right: 56px;
  border-bottom: 1px solid #e8e8e8;
}
