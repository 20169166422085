/* Toastify Styles */
.Toastify__toast-container {
  &#{&} {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.Toastify__toast {
  &#{&} {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    overflow: visible;
  }
}
.Toastify__toast-body {
  &#{&} {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    overflow: visible;
  }
}

.Toastify__close-button {
  position: absolute;
  right: 10px;
  top: 8px;
}
