@import 'colors';

@mixin input() {
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  height: 40px;
  padding: 4px 12px;
  color: $color-gray-8;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
  background-image: none;
  border: 1px solid $color-gray-5;
  border-radius: 4px;
  outline: none;
  @include transition(all);
  flex-shrink: 0;

  &::placeholder {
    color: #bfbfbf;
  }

  &:hover:not(:disabled):not([readOnly]) {
    @include input-hover();
  }

  &:focus:not(:disabled):not([readOnly]) {
    @include input-focus();
  }

  &[readOnly],
  &:disabled {
    background-color: #f5f5f5;
    color: $color-gray-7;
  }

  &.error {
    border: 1px solid $color-danger;
    color: $color-danger;

    &:hover,
    &:hover:not(:disabled):not([readOnly]) {
      border-color: $color-danger;
    }

    &:focus,
    &:focus:not(:disabled):not([readOnly]) {
      border-color: $color-danger;
      box-shadow: 0px 0px 4px #{transparentize($color-danger, 0.4)};
    }
  }

  &.hasValue:not(:disabled):not([readOnly]) {
    background: $color-light-purple;
  }
}

@mixin input-focus {
  box-shadow: 0px 0px 4px #{transparentize($color-primary, 0.4)};
  border: 1px solid $color-primary;
  outline: none;
}

@mixin input-hover {
  border: 1px solid $color-primary;
}

@mixin modal-container {
  padding-right: 24px;
  padding-left: 24px;
}

@mixin container-outer {
  max-width: 1248px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

@mixin container {
  padding: 0 16px;
}

@mixin container-guest {
  max-width: 1248px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}
