@import 'styles/variables';

.wrapper {
  .errorMessage {
    display: block;
    margin-top: 2px;
    font-size: 14px;
    color: $color-red-4;
    white-space: pre-wrap;
  }

  .form {
    // width: 700px;
    padding-top: 24px;
  }

  .frame {
    margin-top: 24px;
  }

  .control {
    height: 64px;
  }
}

.subTabs {
  display: flex;

  .tab {
    position: relative;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 20px;
    margin-top: 40px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      background-color: transparent;
    }
  }

  .tab + .tab {
    margin-left: 64px;
  }

  .activeTab {
    // color: $color-secondary;
    &:after {
      background-color: $color-secondary;
    }
    .tabNumber {
      border-color: $color-secondary;
      background-color: #ffffff;
    }
  }
}

.fieldsList {
  border: 1px solid rgba(26, 34, 59, 0.16);
  border-radius: 8px;
  margin-top: 24px;
  padding: 20px;

  .fieldsTitle {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 24px;
    font-weight: 400;
  }

  .fieldsRow {
    display: flex;
    align-items: flex-start;
  }

  .fieldsRow:not(:first-of-type) {
    margin-top: 16px;
  }

  .rowNumber {
    margin-right: 6px;
    width: 14px;
  }

  .rowField {
    margin: 0;
    flex-shrink: 0;
    .control {
      min-width: auto;
    }

    &:not(:first-of-type) {
      margin-left: 6px;
    }
  }

  .delBtn {
    flex-shrink: 0;
    margin-left: 6px;
  }

  .delIcon {
    width: 18px;
    height: 18px;
  }

  .buttonsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px;
  }

  .fieldsListBtn {
    margin-top: 16px;
    margin-left: 20px;
  }
}

.contractor {
  .rowField {
    &:first-of-type input {
      width: 160px;
    }

    &:nth-of-type(2) {
      flex-grow: 1;
    }

    &:nth-of-type(3) input {
      width: 130px;
    }
  }
}

.document {
  .rowField {
    &:first-of-type {
      flex-grow: 1;
      width: 200px;
    }

    &:nth-of-type(2) {
      flex-grow: 1;
      width: 200px;
    }

    &:nth-of-type(3) {
      input {
        width: 150px;
      }
    }

    &:nth-of-type(4) {
      width: 140px;
    }
  }
}

.showInEsf {
  padding-left: 24px;
  margin-top: 8px;
}
