@import 'styles/variables';

.double-date--t2 {
  position: relative;
  display: flex;
  @include transition(all);

  .double-date--picker {
    input:not(:disabled):not([readOnly]) {
      &:hover {
      }
      &:focus {
      }
    }
  }

  .double-date--picker__left {
    .react-datepicker__input-container {
      &::before {
      }
    }
  }

  .double-date--picker__right {
    margin-left: 24px;
  }

  .double-date--picker-label {
    // color: rgba(26, 34, 59, 0.55);
    text-transform: capitalize;
  }
}
