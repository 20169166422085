@import 'styles/variables';

.wrapper {
  width: 600px;

  .content {
    min-height: 200px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .datepicker {
    width: 250px;
  }

  .textarea {
    // width: 350px;
  }
}
