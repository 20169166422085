@import 'styles/variables';

.wrapper {
  padding-top: 20px;

  .inner {
    margin-top: 40px;
    padding: 0 24px;
  }
}
