$color-gray-1: #ffffff;
$color-gray-2: #fafafa;
$color-gray-3: #f5f5f5;
$color-gray-4: #e8e8e8;
$color-gray-5: #d9d9d9;
$color-gray-6: #bfbfbf;
$color-gray-7: #8c8c8c;
$color-gray-8: #595959;
$color-gray-9: #262626;
$color-gray-10: #000000;

$color-red-4: #ff4236;
$color-gold-6: #faad14;
$color-green-5: #73d13d;
$color-green-6: #52c41a;
$color-green-7: #34c759;
$color-yellow-5: #ffec3d;
$color-yellow-6: #ff9500;
$color-blue-5: #40a9ff;
$color-light-purple: #f1f0ff;
$color-light-purple2: #f5f5ff;

$color-primary: #353078;
$color-primary-dark: #2c2653;
$color-primary-dark-2: #3e4861;
$color-primary-dark-3: #2a3450;
$color-secondary: #d9b673;
$color-filled: $color-light-purple;

$color-text: #262626;

// Цвета для Т2
$color-text-2: #1a223b;
$color-red-5: #ff4236;
$color-red-6: #f5222d;
$color-red-7: #ff3b30;
$color-surface-1: #f6f7fc;

$color-danger: $color-red-6;
