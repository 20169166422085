@import 'styles/variables';

.wrapper {
  width: 100px;
  min-width: auto;
}

.T1 {
  width: 100px;
}

.T2 {
  width: 70px;
}
