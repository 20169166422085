@import 'styles/variables';

.wrapper {
  width: 500px;

  .content {
    padding: 0;
    // padding-top: 24px;
    // padding-bottom: 24px;
    // max-height: 550px;
    // overflow-y: auto;
  }

  .button + .button {
    margin-left: 8px;
  }

  .field + .field {
    margin-top: 12px;
  }

  .resultInfo {
    margin: 0;
    // list-style: none;
    font-size: 14px;
    // padding: 0;
    margin-top: 16px;
  }

  .footer {
    height: 48px;
  }
}
