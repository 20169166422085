@import 'styles/variables';

.double-date {
  position: relative;
  // width: 350px;
  display: flex;
  border-radius: 4px;
  border: 1px solid $color-gray-5;
  @include transition(all);

  &::before {
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translate(-28px, -50%);
    content: '';
    width: 24px;
    height: 24px;
    background-image: url('./swap-right.svg');
    background-size: cover;
    pointer-events: none;
    z-index: 5;
  }

  &:focus-within {
    @include input-focus;
  }

  &:hover:not(:disabled) {
    @include input-hover;
  }

  &.double-date--error,
  &.double-date--error:hover {
    border-color: $color-danger;
  }

  .double-date--picker {
    input:not(:disabled):not([readOnly]) {
      border: none;
      &:hover {
        border: none;
      }
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }

  .double-date--picker__left {
    .react-datepicker__input-container {
      &::before {
        background-image: none;
      }
    }
  }

  .double-date--picker__right {
    // .react-datepicker__tab-loop {
    //   position: relative;
    // }
    // .react-datepicker-popper {
    //   left: auto !important;
    //   right: 0 !important;
    //   transform: none !important;
    // }
  }
}
