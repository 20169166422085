@import 'styles/variables';

.wrapper {
  .card {
    width: fit-content;
    margin: 80px auto 0;
    padding: 56px;
  }

  .successText {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
  }

  .subText {
    text-align: center;
    margin-top: 40px;
    color: rgba(26, 34, 59, 0.7);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    white-space: pre;
  }

  .doneButton {
    margin-top: 40px;
  }
}
