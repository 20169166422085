@import 'styles/variables';

.wrapper {
  width: 600px;
  font-size: 14px;
}

.body {
  @include modal-container();
  padding-top: 24px;
  padding-bottom: 16px;
}
