@import 'styles/variables';

.wrapper {
  h1 {
    font-size: 40px;
    line-height: 48px;
    color: #1a223b;
    font-weight: 700;
    margin-bottom: 40px;
  }

  .date {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: rgba(26, 34, 59, 0.55);
    margin-bottom: 8px;
    margin-top: 0;
  }

  .more {
    display: flex;
    padding-top: 12px;
    border-top: 1px solid rgba(26, 34, 59, 0.16);

    .column {
      width: 396px;
      display: flex;
    }

    .column:first-child {
      padding-right: 12px;
    }
    .column:nth-child(2) {
      padding-left: 12px;
      border-left: 1px solid rgba(26, 34, 59, 0.16);
    }

    .item {
      flex-grow: 1;
      height: 156px;
    }
  }
}

.newsBody {
  a {
    color: #2c2653;
    text-decoration: underline;
  }
}
