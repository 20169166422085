@import 'styles/variables';

.wrapper {
  @include modal-container();
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e8e8e8;

  button + button {
    margin-left: 8px;
  }
}
