@import 'styles/variables';

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: $nav-aside-max;
  @include transition(padding);

  a + a {
    margin-left: 24px;
  }

  a:first-of-type {
    margin-left: auto;
  }

  &.minimized {
    padding-left: $nav-aside-min;
  }
}
