@import 'styles/variables';

.wrapper {
  .content {
    width: 700px;
    padding: 24px;
  }

  .card + .card {
    margin-top: 24px;
  }

  .leftColumn {
    font-weight: 500;
  }

  .danger {
    color: $color-danger;
  }
}
