@import 'styles/variables';

.wrapper {
  width: 100%;
  .nodeHeader {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .nodeLabel {
    margin-right: auto;
    width: 60%;
  }

  .nodeBin {
    margin-left: 8px;
  }

  .nodeClosed {
    margin-left: 16px;

    &.closed {
      color: $color-danger;
    }
  }
}
