@import 'styles/variables';

.form {
  margin-top: 40px;

  .control {
    height: 64px;
  }

  .links {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    a {
      color: $color-primary;
    }
  }

  .button {
    display: block;
    margin-top: 24px;
  }
}
