@import 'styles/variables';

.withFlag {
  display: inline-flex;
  align-items: center;
  .flag {
    margin-right: 5px;
  }
}

.capitalize {
  text-transform: capitalize;
}
