@import 'styles/variables';

.wrapper {
  &.modal {
    width: 1042px;

    .container {
      @include modal-container();
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}
