@import 'styles/variables';

.wrapper {
  padding-top: 48px;
  @include container-outer();

  .description {
    max-width: 800px;
    font-size: 20px;
    line-height: 36px;
    color: #595959;
  }

  .form {
    margin-top: 32px;
  }

  .uploader {
    width: 200px;
    margin-top: 16px;
  }

  .submitButton {
    margin-top: 40px;
  }
}
