@import 'styles/variables';

.wrapper {
  width: 400px;

  &.noHeader {
    padding-top: 28px;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 24px;
    min-height: auto;
    max-height: 400px;
    overflow: auto;
  }
}

.alert {
  .footer {
  }
}
