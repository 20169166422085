@import 'styles/variables';

.wrapper {
  width: 600px;

  .content {
    padding-bottom: 8px;
  }

  .button {
    display: block;
    margin: 8px 0;
  }
}
