@import 'styles/variables';

.wrapper {
  padding: 8px;

  table {
    border-collapse: collapse;
    font-size: 13px !important;
  }
  table th,
  table td {
    padding: 5px;
    border: 1px solid grey;
  }
  @media print {
    .print-buttons-container {
      display: none;
    }
  }
  .title {
    margin-top: 30px;
  }
  .printer {
    margin-right: 5px;
  }
  .buttonsContainer {
    position: fixed;
    left: 0;
    top: 0;
    font-family: Verdana, Arial, sans-serif;
    @media print {
      display: none;
    }
  }

  .searchParams {
    margin-top: 16px;
    table {
      td {
        white-space: pre;
      }
    }
  }
}
