@import 'styles/variables';

.wrapper {
  width: 700px;

  .content {
    padding-bottom: 12px;
    max-height: 600px;
    overflow: auto;
  }

  .footer {
    justify-content: flex-start;
  }

  .button {
    flex-shrink: 0;
    margin-left: auto;
  }

  .caption {
    padding-right: 20px;
  }
}
