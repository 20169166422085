@import 'styles/variables';

.wrapper {
  .header {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
  }

  .content {
    display: none;
  }

  .icon {
    margin: 0;
    flex-shrink: 0;
  }

  &.opened {
    > .content {
      display: block;
    }

    > .header > .icon {
      // margin: 0 0 2px auto;
      margin-bottom: 2px;
    }
  }

  &.animated {
    > .content {
      display: block;
      overflow: hidden;
      @include transition(height);
    }
    &.opened {
      > .content {
        display: block;
      }
    }
  }
}
