@import 'styles/variables';

.wrapper {
  padding-top: 8px;
  padding-left: 24px;
  padding-right: 24px;
  min-height: 100px;

  ul {
    list-style: disc;
    padding-left: 20px;
    margin-bottom: 0;
  }
}
