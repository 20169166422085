@import 'styles/variables';

.wrapper {
  background: $color-primary;
  color: $color-text-2;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  min-height: 100%;
  min-width: 1440px;
  display: flex;
  flex-direction: column;
  padding-left: $taxpayer-aside-width;
  @include transition(padding, color);

  .header {
    height: $taxpayer-header-height;
    z-index: $z-header;
    position: fixed;
    right: 0;
    left: $taxpayer-aside-width;
    top: 0;
  }

  .content {
    flex: 1 0 auto;
    position: relative;
    background-color: $color-surface-1;
    // padding-bottom: 24px;
    padding-top: $taxpayer-header-height;
    width: 100%;
  }

  .aside {
    flex-shrink: 0;
    position: fixed;
    background: $color-primary;
    top: 0;
    left: 0;
    bottom: 0;
    color: #fff;
    width: $taxpayer-aside-width;
    @include transition(width);
    z-index: $z-aside;
  }
}
