@import 'styles/variables';

.wrapper {
  position: relative;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .textButton {
    -webkit-appearance: auto;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    font-weight: 700;
    @include transition(color);
    color: $color-primary-dark;
    display: flex;
    align-items: center;
    height: 25px;
    &:hover {
      color: lighten($color-primary-dark, 10);
    }
  }

  .inactive {
    color: #a2a3a3;
    &:hover {
      color: lighten(#a2a3a3, 5);
    }
  }

  .langButton {
    padding-top: 2px;
  }

  .langButton + .langButton {
    margin-left: 12px;
  }

  .eyeButton {
    margin-left: 18px;
  }
}

.reversed {
  .textButton {
    color: #d9b673;
    &:hover {
      color: lighten(#d9b673, 5);
    }
  }

  .inactive {
    color: #a2a3a3;
    &:hover {
      color: lighten(#a2a3a3, 5);
    }
  }
}
