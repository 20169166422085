@import 'styles/variables';

.wrapper {
  width: 600px;
  .content {
    padding: 0;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .body {
    margin: 18px 24px 24px 23px;
  }

  .field {
    overflow: hidden;
  }

  .label {
    flex-shrink: 1;
  }
}
