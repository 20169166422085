@import 'styles/variables';

.tooltip.tooltip {
  // transform: translateX(-50%);
  max-width: 350px;
  padding: 12px;
  border-radius: 4px;
  background: #1a223b;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  --rt-opacity: 1;
  z-index: $z-tooltip;
}
