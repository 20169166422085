@import 'styles/variables';

.wrapper {
  position: relative;
  color: $color-gray-7;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
  height: auto;
  padding: 0 24px 0 8px;
  line-height: 24px;
  white-space: nowrap;
  background: $color-gray-2;
  border: 1px solid $color-gray-5;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;

  .button {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .icon {
    width: 12px;
    height: 12px;
  }
}

.T2 {
  background: #f6f7fc;
  border: 1px solid rgba(26, 34, 59, 0.24);
  border-radius: 6px;
  line-height: 29px;
  padding-top: 1px;
  color: rgba(26, 34, 59, 0.55);
  font-weight: 700;

  .button {
    color: rgba(26, 34, 59, 0.4);

    &:hover {
      color: rgba(26, 34, 59, 0.3);
    }
  }
}
