@import 'styles/variables';

.wrapper {
  .card {
    width: 982px;
    margin: 40px auto 0;
  }

  .loader {
    padding: 200px 0 250px;
  }

  h1,
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  h1 {
    text-align: center;
  }

  h2 {
    margin-top: 40px;
  }

  .subTitle {
    padding-bottom: 20px;
    margin-bottom: 0;
    border-bottom: 2px solid $color-secondary;
    display: inline-block;
  }

  .container {
    width: 700px;
    margin: 0 auto;
  }

  .hr {
    height: 1px;
    background-color: #d9d9d9;
    margin: 0;
    margin-top: -1px;
    border: none;
  }

  .caption {
    height: 30px;
    color: rgba(26, 34, 59, 0.55);
    line-height: 30px;
    font-size: 13px;
    border-left: 4px solid $color-secondary;
    padding-left: 10px;
    margin-top: 20px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .field + .field {
    margin-top: 24px;
  }

  .doubleField {
    display: flex;
  }

  .colField {
    margin: 0;
    flex: 1 0 auto;
    width: 200px;
  }

  .colField + .colField {
    margin-left: 6px;
  }
}

.tabs {
  display: flex;

  .tab {
    position: relative;
    height: 60px;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex: 1 0 25%;
    user-select: none;
    padding-bottom: 20px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      background-color: transparent;
    }
  }

  .activeTab {
    // color: $color-secondary;
    &:after {
      background-color: $color-secondary;
    }
    .tabNumber {
      border-color: $color-secondary;
      background-color: #ffffff;
    }
  }

  .tabNumber {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #f5f5f5;
    margin-right: 10px;
  }
}

.frame {
  border: 1px solid rgba(26, 34, 59, 0.16);
  border-radius: 8px;
}

.credentials {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  padding: 20px;
  gap: 20px;

  .label {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: rgba(26, 34, 59, 0.7);
  }

  .text {
    line-height: 1.2;
    margin-top: 2px;
  }
}

.enterpriseCredentials {
  grid-template-columns: 1.5fr 1fr;
}
.privateCredentials {
  grid-template-columns: 1fr;
}
