@import 'styles/variables';

.wrapper {
  width: 100%;
  position: relative;

  .loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner {
    // width: 100%;

    &.scrollable {
      overflow-x: auto;
    }
  }

  table {
    // min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 14px;
    line-height: 20px;
    width: 100px;
    overflow: visible;
  }

  tbody {
    background: white;
  }

  tr {
    // display: flex;
    // align-items: center;
    // min-height: 46px;
  }

  .selectedRow {
    background-color: rgba(93, 74, 255, 0.16);
  }

  td,
  th {
    border: 1px solid rgba(26, 34, 59, 0.16);
    padding: 5px 20px 4px;
    text-align: left;
    height: 30px;
  }

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  th {
    position: relative;
    color: rgba(26, 34, 59, 0.8);
    background: #f6f7fc;
    text-transform: uppercase;
    user-select: none;
    font-size: 12px;
    vertical-align: top;
    @include transition(background-color);
    &.sortableTh {
      cursor: pointer;
      &:hover {
        background-color: darken(#f6f7fc, 1);
      }
      &:active {
        background-color: darken(#f6f7fc, 3);
      }
    }
    &.sortedTh {
      padding-right: 30px;
    }
  }

  .resizeHandler {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 5px;
    background-color: transparent;
    cursor: col-resize;
  }

  .checkCell {
    width: 40px;
    padding: 0;
    text-align: center;
    .checkBox {
      margin-top: 1px;
    }
  }

  th.checkCell {
    padding-top: 5px;
  }

  tfoot {
    th {
      font-size: 14px;
    }
  }

  .totalLabel {
    text-align: right;
    text-transform: capitalize;
  }

  &.selectable {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  &.oneLine {
    tbody {
      td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &.rounded {
    table {
      /* Change these properties */
      --border: 1px solid rgba(26, 34, 59, 0.16);
      border-radius: 6px;

      /* Don't change these properties */
      border-spacing: 0;
      border-collapse: separate;
      border: var(--border);
      overflow: hidden;

      th,
      td {
        border: none;
      }

      /* Apply a border to the right of all but the last column */
      th:not(:last-child),
      td:not(:last-child) {
        border-right: var(--border);
      }

      /* Apply a border to the bottom of all but the last row */
      th,
      tr:not(:last-child) > td {
        border-bottom: var(--border);
      }
    }
  }

  .sortIcon {
    position: absolute;
    right: 13px;
    top: 8px;
    color: transparentize($color-text-2, 0.55);
    width: 15px;
    height: 15px;
  }

  .sortDesc {
    transform: scaleY(-1);
  }
}

.tabs {
  display: flex;

  .tab {
    position: relative;
    height: 40px;
    width: 220px;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: rgba(26, 34, 59, 0.55);
    cursor: pointer;
    user-select: none;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      background-color: transparent;
    }
  }

  .activeTab {
    color: #2c2653;
    &:after {
      background-color: #2c2653;
    }
  }

  .tabIcon {
    margin-right: 10px;
  }
}
