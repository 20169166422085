@import 'styles/variables';

.wrapper {
  width: 500px;
  min-height: 300px;

  .content {
    padding-bottom: 24px;
  }
}
