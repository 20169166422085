@import 'styles/variables';

.wrapper {
  width: 500px;

  .content {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
