@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid rgba(26, 34, 59, 0.16);
  padding: 16px 24px;

  button + button {
    margin-left: 8px;
  }
}
