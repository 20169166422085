@import 'styles/variables';

.grid {
  margin-top: 12px;
  display: flex;

  .column {
    width: 396px;
    display: flex;
    flex-direction: column;
  }
  .column:first-child {
    padding-right: 12px;
    .item {
      min-height: 180px;
    }

    .item + .item {
      margin-top: 12px;
    }
  }
  .column:nth-child(2) {
    padding-left: 12px;
    border-left: 1px solid rgba(26, 34, 59, 0.16);
    .item {
      min-height: 100px;
      flex-grow: 1;
    }

    .item + .item {
      margin-top: 12px;
    }
  }
}
