@import 'styles/variables';

.wrapper {
  height: 100%;
  font-size: 16px;

  background: url('../../../assets/hills.png'), $color-primary;
  background-position: 20% bottom;
  background-repeat: repeat-x;
  background-size: 650px;
  .content {
    background-color: transparent;
    color: #fff;
  }
  .footer {
    height: 580px;
  }

  .inner {
    @include container-guest();
    height: 100%;
    padding-top: 40px;
    display: flex;
  }

  .col {
    width: 33.333%;
    h3 {
      margin: 0;
      margin-bottom: 4px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      line-height: 24px;
      margin-top: 4px;
    }
  }

  .mail,
  .phone {
    display: block;
    color: $color-secondary;
    text-decoration: underline;
  }

  .subText {
    display: block;
    font-size: 13px;
    line-height: 16px;
    color: $color-gray-6;
  }

  .hline {
    display: block;
    height: 4px;
    background-color: #fff;
    width: 100%;
    margin-bottom: 9px;
  }

  .social {
    margin-top: 4px;
    a {
      color: $color-gray-6;
    }
  }
}
