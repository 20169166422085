@import 'styles/variables';

.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 72px;
  border-top: 1px solid $color-gray-4;
}
