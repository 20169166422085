@import 'styles/variables';

.wrapper {
  width: 700px;

  .content {
    padding-bottom: 24px;
    padding-top: 24px;
  }

  .leftColumn {
    font-weight: 500;
  }
}
