@import 'styles/variables';

$thin-line-color: #e0e0e0;

.wrapper {
  padding-bottom: 96px;

  .addCommentForm {
    display: flex;
    position: relative;
  }

  .field {
    flex-grow: 1;
    width: auto;
  }

  .input {
  }

  .button {
    margin-left: 10px;
    flex-shrink: 0;
  }

  .loaderOverlay {
    padding: 0;
  }

  .loader {
    top: -18px;
  }

  .list {
    position: relative;
    margin-bottom: 24px;
  }

  .comment {
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    .name {
      font-weight: 700;
      line-height: normal;
    }

    .text {
      line-height: normal;
    }

    .date {
      color: #616161;
      font-size: 12px;
      line-height: normal;
    }
  }

  .listLoader {
    width: 300px;
  }
}
