@import 'styles/variables';

.wrapper {
  .header {
    margin-bottom: 8px;
    position: relative;

    .mainForm {
      display: flex;
    }

    .navWrapper {
      margin-left: auto;
      display: flex;
      align-items: flex-end;

      button {
        min-width: 100px;
      }

      button + button {
        margin-left: 8px;
      }
    }

    .advancedButton {
      border: none;
      background-color: transparent;
      white-space: nowrap;
      &:focus {
        outline: none;
      }
    }

    .chevronIcon {
      width: 10px;
      height: 6px;
    }
  }

  .mainWrapper {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    padding-right: 8px;
  }
}

.activeFilters {
  height: 32px;

  .filterTag + .filterTag {
    margin-left: 6px;
  }
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  // min-height: 100%;
  background: rgba(1, 24, 52, 0.22);
  z-index: $z-modalOverlay;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.advancedModal {
  padding: 12px 0 0;
  position: absolute;
  top: calc(100%);
  right: 0;
  background-color: #fff;
  border-radius: 8px;
  z-index: 550;
  box-shadow: 0px -1px 12px rgba(26, 34, 59, 0.07), 0px -2px 16px rgba(26, 34, 59, 0.12);

  .advancedWrapper {
    display: flex;
    max-height: calc(100vh - 250px);
    overflow: auto;
    padding: 24px 50px 0 24px;

    .columnWide {
      width: 690px;
      flex-shrink: 0;
    }

    .columnWide + .columnWide {
      margin-left: 24px;
    }

    .labelWide {
      width: 244px;
    }
  }

  .advancedFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    padding: 0 50px 24px 24px;

    button {
      min-width: 100px;
    }

    button + button {
      margin-left: 8px;
    }
  }
}

.advancedAside {
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 550;
  box-shadow: 0px -2px 16px 0px rgba(26, 34, 59, 0.12), 0px -1px 12px 0px rgba(26, 34, 59, 0.07);
  width: 420px;

  .advancedHeader {
    display: flex;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    border-bottom: 1px solid rgba(26, 34, 59, 0.16);
  }

  .closeButton {
    margin-left: auto;
    width: 72px;
    height: 56px;
    .closeIcon {
      width: 24px;
      height: 24px;
    }
  }

  .advancedWrapper {
    padding: 16px;
    overflow-y: auto;
    height: calc(100% - 2 * 64px);
  }

  .advancedFooter {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 64px;
    border-top: 1px solid rgba(26, 34, 59, 0.16);
    display: flex;
    align-items: center;
    padding: 0 16px;

    button {
      // min-width: 100px;
      flex: 1 0 100px;
    }

    button + button {
      margin-left: 8px;
    }
  }
}
