@import 'styles/variables';

.wrapper {
  margin-top: 16px;

  .section {
    margin-top: 24px;
  }

  .control {
    width: 300px;
  }

  .submit {
    margin-top: 8px;
  }
}
