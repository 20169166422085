@import 'styles/variables';

.wrapper {
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 550;
  box-shadow: 0px -2px 16px 0px rgba(26, 34, 59, 0.12), 0px -1px 12px 0px rgba(26, 34, 59, 0.07);
  width: 384px;

  .header {
    display: flex;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    border-bottom: 1px solid rgba(26, 34, 59, 0.16);
  }

  .closeButton {
    margin-left: auto;
    width: 72px;
    height: 56px;
    .closeIcon {
      width: 24px;
      height: 24px;
    }
  }

  .content {
    padding: 16px;
    overflow-y: auto;
    height: calc(100% - 2 * 64px);
  }

  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 64px;
    border-top: 1px solid rgba(26, 34, 59, 0.16);
    display: flex;
    align-items: center;
    padding: 0 16px;

    button {
      // min-width: 100px;
      flex: 1 0 100px;
    }

    button + button {
      margin-left: 8px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  // min-height: 100%;
  background: rgba(1, 24, 52, 0.22);
  z-index: $z-modalOverlay;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
