@import 'styles/variables';

$size: 20px;

.wrapper {
  height: $size;
  min-width: $size;
  background: $color-primary;
  color: white;
  border-radius: $size / 2;
  text-align: center;
  line-height: $size;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 0 0 1px #fff;
  padding: 0 6px;
  white-space: nowrap;
}
