@import 'styles/variables';

.wrapper {
  @include container-guest();
  padding-top: 24px;
  color: $color-text-2;
}

.content {
  padding-right: 408px;
  position: relative;
}

.article {
  text-align: left;
  font-size: 20px;
  line-height: 32px;

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 40px;
  }

  h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 24px;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 24px;
  }

  strong {
    font-weight: 700;
  }

  ul {
    li {
      list-style-image: url('../../assets/icons/dash.svg');
    }

    li + li {
      margin-top: 20px;
    }
  }

  .video {
    width: 100%;
    padding-top: 56%;
    position: relative;
    margin-bottom: 24px;

    iframe {
      @include coverdiv();
    }
  }

  .section {
    margin-bottom: 80px;
  }

  .guides {
    list-style: none;
    padding: 0;
    li {
      list-style-image: none;
      & + li {
        margin-top: 28px;
      }
    }
  }

  .guideLink {
    color: #595959;
    display: inline-flex;
    align-items: center;
    .docLinkIcon {
      color: $color-primary;
      margin-right: 12px;
    }
  }
}

.insideMenu {
  position: absolute;
  left: 792px + 24px;
  top: 0;

  .menuItem {
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: $color-text-2;

    &:hover {
      color: lighten($color-text-2, 15);
    }
  }

  .menuItem + .menuItem {
    margin-top: 16px;
  }

  .activeLink {
    color: rgba(26, 34, 59, 0.55);
    &:hover {
      color: rgba(26, 34, 59, 0.55);
    }
  }
}
