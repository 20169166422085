@import 'styles/variables';

.wrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: transparent;
  text-align: center;
  height: 40px;
  padding: 0 14px;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  z-index: 3;
  overflow: hidden;
  @include transition(background-color, color, border);
  outline: none;
  user-select: none;
  white-space: nowrap;
  flex-shrink: 0;

  &.loading {
    cursor: auto;
  }
  &:not(.loading) {
    &:disabled,
    &:disabled:hover,
    &:disabled:active {
      background-color: #f5f5f5;
      border: 1px solid $color-gray-4;
      // box-shadow: inset 0 0 0px 1px #d9d9d9;
      color: #bfbfbf;
      cursor: auto;
    }
  }

  &:focus {
    outline: 1px solid #{transparentize($color-primary, 0.8)};
  }

  .loaderOverlay {
    padding: 0;
    position: absolute;
    .loader {
      transform: scale(80%);
      top: -2em;
      color: $color-gray-4;
    }
  }
}

.fullWidth {
  width: 100%;
}

.icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
}

.iconLeft {
  padding-left: 34px;
  .icon {
    left: 20px;
    transform: translate(-50%, -50%);
  }
}

.iconRight {
  padding-right: 34px;

  .icon {
    transform: translate(50%, -50%);
    right: 20px;
  }
}

.primary,
.primary-upper {
  background: $color-secondary;
  border-color: $color-secondary;
  color: #262626;

  &.loading {
    background-color: #f5f5f5;
    border: 1px solid $color-gray-4;
    color: #bfbfbf;
  }

  &:hover:not(.loading) {
    background-color: #d9c296;
  }
  &:active:not(.loading) {
    background-color: #bfa561;
  }
}

.secondary,
.secondary-upper {
  background-color: #f5f5f5;
  border: 1px solid $color-gray-5;

  &:hover {
    background-color: #fafafa;
    border-color: #e8e8e8;
  }
  &:active {
    background-color: #e8e8e8;
    border-color: #bfbfbf;
  }
}

.primary-upper,
.secondary-upper {
  padding: 2px 20px 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.8px;
}

.outlined {
  border: 1px solid #d9d9d9;
  color: #595959;

  &:hover {
    border: 1px solid #{$color-secondary};
    color: $color-secondary;
  }
  &:active {
    border-color: #bfa561;
    color: #bfa561;
  }
}

.medium {
}

.xsmall {
  height: 24px;
  font-size: 14px;
}

.small {
  height: 32px;
  font-size: 14px;
}

.large {
  height: 56px;
  min-width: 160px;
  padding: 0 17px;
}

.fzSmall {
  font-size: 14px;
}
