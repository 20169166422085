// width
@mixin r($width) {
  @media only screen and (max-width: $width+ 'px') {
    @content;
  }
}

@mixin rmin($width) {
  @media only screen and (min-width: $width+ 'px') {
    @content;
  }
}

// height
@mixin rh($height) {
  @media only screen and (max-height: $height + 'px') {
    @content;
  }
}

@mixin rhmin($height) {
  @media only screen and (min-height: $height + 'px') {
    @content;
  }
}

$browser-context: 16; // Default

@mixin coverdiv() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@mixin transition($props...) {
  $result: ();

  @for $i from 1 through length($props) {
    $prop: nth($props, $i);
    $result: append($result, $prop);
    $result: append($result, ease-in-out 0.3s);

    @if $i != length($props) {
      $result: append(
        $result,
        unquote(
          $string: ',',
        )
      );
    }
  }

  transition: $result;
}

@mixin shortTransition($props...) {
  $result: ();

  @for $i from 1 through length($props) {
    $prop: nth($props, $i);
    $result: append($result, $prop);
    $result: append($result, ease-in-out 0.1s);

    @if $i != length($props) {
      $result: append(
        $result,
        unquote(
          $string: ',',
        )
      );
    }
  }

  transition: $result;
}

@mixin shadow1() {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

@mixin shadow2() {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

@mixin shadow3() {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

@mixin custom-scroll() {
  // overflow-y: scroll;

  /* firefox scroll */
  scrollbar-color: #6b767d rgba(255, 255, 255, 0);
  scrollbar-width: thin;

  /* Google scroll */
  &::-webkit-scrollbar {
    background-color: rgba(102, 102, 128, 0.1);
    width: rem(14px);
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(102, 102, 128);
    border-radius: rem(3px);
  }
}
