@import 'styles/variables';

.wrapper {
  .input,
  .count {
    max-width: 270px;
  }

  &:not(:first-child) {
    margin-top: 4px;
  }
}
