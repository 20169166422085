@import 'styles/variables';

.wrapper {
  text-align: right;
  position: relative;

  .inner {
    cursor: pointer;
  }

  .name {
    font-weight: 500;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    user-select: none;
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .chevronIcon {
    position: absolute;
    top: 12px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    margin-left: 5px;
  }

  .tin {
    font-size: 12px;
    color: #a2a3a3;
  }

  .dropdown {
    position: absolute;
    background-color: transparent;
    z-index: $z-profileSelect;
    right: -20px;
  }
}
