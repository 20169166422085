@import 'styles/variables';

.wrapper {
  height: 100%;
  background-color: $color-primary-dark;

  .inner {
    display: flex;
    align-items: center;
    height: 100%;
    @include container-outer();
    padding-left: 18px;
  }

  .langToggle {
    margin-left: auto;
  }

  .langToggleButton {
    color: white;
  }
}
