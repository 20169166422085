@import 'styles/variables';

.wrapper {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: $color-primary;
  border-radius: 50%;

  .letters {
    position: absolute;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}

.threeLetters {
  .letters {
    font-size: 16px;
  }
}
