@import 'styles/variables';

.wrapper {
  width: 700px;

  .content {
    padding-top: 24px;
    padding-bottom: 24px;
    color: $color-gray-8;
    max-height: 650px;
    overflow-y: auto;
  }

  .heading {
    color: $color-gray-9;
    font-weight: 500;
    line-height: 28px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .statuses {
    margin-top: 24px;
    .statusRow {
      height: 56px;
      border-bottom: 1px solid $color-gray-5;
    }
    .statusRow:last-child {
      border-bottom: none;
    }

    .status {
      color: $color-primary;
    }
  }
}
