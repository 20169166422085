@import 'styles/variables';

.wrapper {
  @include container-t2();
  padding-top: 20px;

  .inner {
    margin-top: 40px;
  }

  .goodAmount {
    margin: 32px 0 8px;
  }
}
