@import 'styles/variables';

.wrapper {
  @include transition(opacity);

  &.visualyHidden {
    opacity: 0;
  }

  &.hidden {
    display: none;
  }

  .toast {
    width: 100%;
    padding: 16px 20px;

    svg {
      display: none;
    }
  }
}
