@import 'styles/variables';

.wrapper {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: flex;

  .dataColumn {
    flex-shrink: 0;
  }

  .dataColumn + .dataColumn {
    margin-left: 10px;
  }

  .row {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  .column {
    width: 270px;
  }

  &.alignRight {
    .row {
      justify-content: flex-end;
    }
  }
}
