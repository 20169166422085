@import 'styles/variables';

$lineIndent: 12px;
$childIndent: 80px;
$verticalMargin: 16px;
$lineTop: 90px;

.wrapper {
  --children-line-color1: #262626;
  --children-line-color2: #262626;
  > .nodeRow {
    // display: flex;
    position: relative;
    z-index: 10;

    .expandButton {
      position: absolute;
      width: 24px;
      height: 24px;
      transform: translate(-50%, -50%);
      top: $lineTop - $verticalMargin;
      background-color: white;
      z-index: 10;
      left: -32px;
      cursor: pointer;
      // display: none;
      .expandIcon {
        width: 100%;
        height: 100%;
      }
    }
  }

  > .childNodes {
    position: relative;
    .childNodesOuter {
      // overflow: hidden;
      @include transition(height);
    }
    .childNodesInner {
      padding-left: $lineIndent + $childIndent;
    }
  }
}

.child {
  position: relative;
  padding: #{$verticalMargin} 0 0;

  &::before {
    content: '';
    position: absolute;
    width: $childIndent - 3px;
    height: 3px;
    top: $lineTop;
    left: $childIndent * -1;
    transform: translateY(-50%);
    background-color: var(--children-line-color1);
    z-index: 3;
  }
  &::after {
    content: '';
    position: absolute;
    width: 3px;
    top: 0;
    bottom: 0;
    left: $childIndent * -1;
    background-color: var(--children-line-color1);
  }

  .arrow {
    position: absolute;
    left: -16px;
    top: $lineTop;
    color: var(--children-line-color1);
    transform: translateY(-50%);
    z-index: 10;
  }
}

.lastChild {
  &::after {
    bottom: calc(100% - #{$lineTop});
  }
}

.child.doubleLines {
  &::before {
    height: 8px;
    border-top: 3px solid var(--children-line-color1);
    border-bottom: 3px solid var(--children-line-color2);
    background-color: white;
    left: ($childIndent * -1) + 5px;
    width: $childIndent - 10px;
  }
  &::after {
    width: 8px;
    border-left: 3px solid var(--children-line-color2);
    border-right: 3px solid var(--children-line-color1);
    background-color: white;
  }

  &.lastChild {
    &::after {
      bottom: calc(100% - #{$lineTop} - 4px);
      border-bottom: 3px solid var(--children-line-color2);
    }
  }
}
