@import 'styles/variables';

.wrapper {
  .card {
    width: 360px;
    margin: 80px auto 0;
  }

  h1,
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  h1 {
    text-align: center;
  }

  .links {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    a {
      color: $color-secondary;
    }
  }
}
