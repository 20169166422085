@import 'styles/variables';

.wrapper {
  width: 100%;

  .fileLine {
    position: relative;
    height: 22px;
    display: flex;
    align-items: center;
    background: #f1f0ff;
    margin-top: 4px;
    color: #353078;
    font-size: 14px;
    padding: 0 28px;
    user-select: none;

    .filename {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .filesize {
      margin-left: 4px;
      flex-shrink: 0;
    }

    .paperClipIcon {
      color: $color-gray-7;
      transform: translateY(-50%);
      top: 50%;
    }

    .paperClipIcon {
      position: absolute;
      left: 6px;
    }

    .closeButton {
      position: absolute;
      right: 6px;
      cursor: pointer;
      width: 18px;
      height: 18px;
      color: $color-gray-7;

      &:hover {
        color: $color-secondary;
      }
    }

    .closeIcon {
      width: 18px;
      height: 18px;
    }

    &:not(.fullWidthLine) {
      width: fit-content;
    }
  }

  .fileImage {
    position: relative;
    height: 64px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: #4f31e4;
    font-size: 14px;
    padding: 0 28px 0 64px;
    user-select: none;
    border: 1px solid $color-gray-5;
    width: 400px;

    .image {
      width: 48px;
      height: 48px;
      display: block;
      position: absolute;
      top: 8px;
      left: 8px;
      overflow: hidden;
    }

    .filename {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .paperClipIcon {
      color: $color-gray-7;
      transform: translateY(-50%);
      top: 50%;
    }

    .paperClipIcon {
      position: absolute;
      left: 6px;
    }

    .closeButton {
      position: absolute;
      right: 6px;
      top: 6px;
      cursor: pointer;
      width: 18px;
      height: 18px;
      color: $color-gray-7;

      &:hover {
        color: $color-secondary;
      }
    }

    .closeIcon {
      width: 18px;
      height: 18px;
    }
  }
}

.fullWidth {
  width: 100%;
}

.T2 {
  .fileLine {
    background: rgba(217, 182, 115, 0.25);
    padding-right: 40px;
  }
}
