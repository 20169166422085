@import 'styles/variables';

.wrapper {
  padding-top: 48px;
  @include container-outer();

  .form {
    width: 800px;
    color: #595959;
    font-size: 20px;
    line-height: 36px;
  }

  .formTitle {
    font-weight: 500;
    margin: 0 0 8px;
  }

  .formText {
    margin: 0 0 24px;
  }

  .subText {
    font-size: 14px;
    color: #8c8c8c;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    margin: 8px 0 16px;
    width: 312px;
  }

  .button {
    margin-top: 40px;
  }
}
