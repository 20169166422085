@import 'styles/variables';

.wrapper {
  height: 100%;
  .header {
    display: flex;
  }

  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content {
    padding: 24px;
    height: 100%;
    overflow-y: auto;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;

    button {
      width: calc((100% - 8px) / 2);
    }

    button + button {
      margin-left: 8px;
    }
  }
}
