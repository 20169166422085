@import 'styles/variables';

.wrapper {
  width: 400px;

  .content {
    padding-top: 32px;
    padding-bottom: 24px;
  }
}
