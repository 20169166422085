@import 'styles/variables';

.wrapper {
  width: 450px;

  .content {
    position: relative;
    padding-top: 20px;
    padding-bottom: 8px;
    min-height: 190px;
  }

  .button + .button {
    margin-left: 8px;
  }

  .loader {
    position: absolute;
    padding: 24px 0 0;
    bottom: 50px;
    left: 0;
    right: 0;
  }

  .result {
    margin-top: 12px;
    .importedTitle {
      font-size: 14px;
      line-height: 1.4;
      font-weight: 500;
      margin: 4px 0;
    }
    .resultItem {
      font-size: 14px;
      margin: 4px 0;
    }
  }

  .expampleLink {
    font-size: 14px;
    margin-bottom: 8px;
    text-align: right;
  }
}
