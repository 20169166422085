@import 'styles/variables';

.wrapper {
  .content {
    display: flex;
  }

  .column {
    width: 400px;
    padding: 12px 24px 24px 24px;

    &:not(:first-child) {
      border-left: 1px solid $color-gray-4;
    }
  }
}
