@import 'styles/variables';

.wrapper {
  padding: 32px 40px;
  .card {
    border-radius: 10px;
    border: 1px solid var(--Grey-100, #dcdee5);
    background: #fff;
    box-shadow: 0px 5px 20px 0px rgba(50, 40, 137, 0.05);
  }

  .header {
    height: 68px;
    padding: 20px 20px 0;
    display: flex;
    align-items: flex-end;
  }

  .tabs {
    width: 100%;
    .tab:nth-child(3) {
      margin-left: auto;
      justify-content: flex-end;
      padding-right: 16px;
    }
  }

  .filterButton {
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }

  .chevronIcon {
    width: 10px;
    height: 6px;
  }

  .emptyList {
    position: relative;
    height: 400px;
    background-image: url('./images/messages.svg');
    background-repeat: no-repeat;
    background-position: center 42%;

    .noMessages {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      bottom: 50px;
      color: rgba(26, 34, 59, 0.7);
    }
  }
}
