@import 'styles/variables';

.wrapper {
  width: 500px;

  .content {
    padding-bottom: 12px;
  }

  .header {
    height: 80px;
    white-space: pre-line;
  }

  .list {
    padding-left: 17px;
  }

  .imgContainer {
    display: flex;
    justify-content: center;
    height: 200px;
    img {
      display: block;
      height: 200px;
      width: 200px;
    }
  }

  .gudeLink {
    text-decoration: underline;
    color: $color-text-2;
  }
}
