@import 'styles/variables';

.wrapper {
  position: relative;
  white-space: nowrap;
  overflow-x: hidden;
  border-radius: 8px;
  .inner {
    position: relative;
    transition: left 0.3s;
    padding: 0 6px 0 1px;
  }

  .control {
    position: absolute;
    width: 40px;
    background: #757a87;
    color: #fff;
    top: 0;
    bottom: 0;
    cursor: pointer;
    user-select: none;
    z-index: 100;
  }

  .controlLeft {
    width: 41px;
    right: 40px;
    border-radius: 8px 0px 0px 8px;
    display: none;
    border-right: 1px solid $color-gray-7;
  }

  .controlRight {
    right: 0;
    border-radius: 0px 8px 8px 0px;
    display: none;
  }

  .icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 7px;
    height: 11px;
  }
}

.showControl {
  .controlRight {
    display: block;
  }
  .controlLeft {
    display: block;
  }
}
