@import 'styles/variables';

.wrapper {
  .link {
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 35px 0 24px;
    @include transition(padding, color, background);
    color: $color-gray-9;
    cursor: pointer;
    min-width: 200px;

    &:hover {
      color: $color-gray-9;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 1;
    visibility: visible;
    @include transition(opacity, visibility);
  }

  &.minimized {
    .link {
      padding-left: 35px;
    }

    .text {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.dense {
    .link {
      height: 28px;
    }
  }
}
