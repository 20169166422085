@import 'styles/variables';

.wrapper {
  width: 100%;
  position: relative;

  .loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner {
    width: 100%;

    &.scrollable {
      overflow-x: auto;
    }
  }

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  tr {
    display: flex;
    align-items: center;
    min-height: 46px;
    font-size: 14px;
  }

  thead {
    display: block;
    background: #fafafa;
    border-bottom: 1px solid $color-gray-4;
    tr {
      font-weight: 500;
    }
    th {
      flex-shrink: 0;
      font-weight: 500;
    }
    th:not(.checkCell) {
      text-align: left;
      padding-left: 8px;
      width: 200px;
    }
  }

  tbody {
    &.loading {
      filter: opacity(0.5) blur(1px);
      pointer-events: none;
    }
    display: block;
    tr {
      display: flex;
      border-bottom: 1px solid $color-gray-4;
      transition: box-shadow 0.2s;
    }
    tr.selected {
      // background-color: #f0f0f0;
      background-color: transparentize($color-primary, 0.92);
    }
    .showHover {
      &:hover {
        @include shadow2();
        .actionRow {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    td {
      flex-shrink: 0;
    }
    td:not(.checkCell) {
      padding-left: 8px;
      width: 200px;
    }
  }

  .checkCell {
    width: 40px;
    flex-shrink: 0;
    align-self: stretch;
    .checkBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  .actionColumn {
    display: flex;
    margin-left: auto;
    .actionIcon + .actionIcon {
      margin-left: 8px;
    }
  }

  .actionRow {
    position: absolute;
    right: 8px;
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility, opacity 0.2s;

    .actionButton + .actionButton {
      margin-left: 8px;
    }
  }

  &.clean {
    thead {
      border: none;
      background: transparent;
    }

    tbody {
      tr {
        border: none;
      }
      td {
        padding: 8px;
      }
    }
  }

  &.selectable {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  &.oneLine {
    tbody {
      td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &.border {
    border: 1px solid $color-gray-4;
  }
}
