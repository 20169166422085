@import 'colors';

@mixin input-t2() {
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  height: 36px;
  padding: 4px 12px;
  color: #1a223b;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c1c4ce;
  border-radius: 6px;
  outline: none;
  @include transition(all);

  &::placeholder {
    color: #bfbfbf;
  }

  &:hover:not(:disabled):not([readOnly]) {
    @include input-hover();
  }

  &:focus:not(:disabled):not([readOnly]) {
    @include input-focus();
  }

  &[readOnly],
  &:disabled {
    background-color: #e9ebf2;
    color: #5b6176;
    // border: 1px solid rgba(26, 34, 59, 0.24);
    // color: $color-gray-7;
  }

  &.error {
    border: 1px solid $color-danger;
    color: $color-danger;

    &:hover,
    &:hover:not(:disabled):not([readOnly]) {
      border-color: $color-danger;
    }

    &:focus,
    &:focus:not(:disabled):not([readOnly]) {
      border-color: $color-danger;
      box-shadow: 0px 0px 4px #{transparentize($color-danger, 0.4)};
    }
  }
}

@mixin container-t2 {
  padding: 0 28px;
}
