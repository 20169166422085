@import 'styles/variables';

.wrapper {
  width: 700px;

  .content {
    padding: 24px;
  }

  .agreeSection {
    margin-top: 12px;
  }

  .agreementContent {
    height: 380px;
  }
}
