@import 'styles/variables';

.wrapper {
  --border-color: #262626;
  --border-style: solid;
  --row-even-color: #{$color-gray-3};
  width: 300px;
  border: 2px var(--border-style) var(--border-color);
  box-shadow: 0px 10px 20px rgba(38, 38, 38, 0.1);
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  @include transition(width);
  user-select: none;

  .header {
    border-bottom: 2px var(--border-style) var(--border-color);
    display: flex;
    height: 44px;
    flex-shrink: 0;
    @include transition(height, width);

    .headerContent {
      padding: 0 10px;
      flex-grow: 1;
      min-width: 0;

      .icon {
        color: var(--border-color);
        margin-right: 5px;
        width: 18px;
        height: 18px;
        flex-shrink: 0;
      }

      a {
        text-decoration: underline;
      }

      .regNumber {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .skinName {
        margin-right: 5px;
      }

      .docType {
        margin-right: 5px;
        white-space: nowrap;
      }
    }

    .headerContentTop {
      display: flex;
      align-items: center;
      font-weight: 500;
      min-width: 0;
      padding-top: 12px;
    }

    .headerContentBottom {
      display: flex;
      align-items: flex-start;
      margin-top: 4px;
      height: 0;
      overflow: hidden;
      @include transition(height);
    }

    .expandButton {
      margin-left: auto;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex-shrink: 0;
    }
  }

  .body {
    padding: 10px 0;
    position: relative;
    height: 116px;
    @include transition(height, width);

    .divider {
      position: absolute;
      border-left: 2px var(--border-style) var(--border-color);
      top: 0;
      bottom: 0;
      left: 50%;
    }
  }

  .row {
    display: flex;
    height: 24px;
    overflow: hidden;
    padding: 0 10px;
    @include transition(height, background-color);
  }

  div.row {
    height: 0;
    background-color: transparent;
  }

  strong.row {
    font-weight: normal;

    &:nth-of-type(even) {
      background-color: var(--row-even-color);
    }
  }

  .cell {
    height: 24px;
    line-height: 24px;
    // user-select: none;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .left {
    padding-right: 10px;
  }

  .right {
    padding-left: 10px;
    font-weight: 500;
  }

  .row.highlight {
    .right {
      color: $color-red-6;
    }
  }
}

.expanded {
  width: 550px;

  .body {
    height: 284px;
  }

  div.row {
    height: 24px;
  }

  .row:nth-child(odd) {
    background-color: var(--row-even-color);
  }

  strong.row:nth-of-type(even) {
    background-color: transparent;
  }

  .header.hasHeaderContentBottom {
    height: 64px;
    .headerContentBottom {
      height: 19px;
    }
  }
}

.skinWriteoff {
  --border-color: #{$color-red-6};
  --border-style: dashed;
}

.skinReturn {
  --border-color: #{$color-gray-9};
  --border-style: dashed;
}

.skinDetail {
  --border-color: #{$color-gold-6};
}

.selected {
  .idWrapper {
    background-color: $color-secondary;
    display: inline-block;
    box-shadow: -2px 0px 0px 0px #{$color-secondary},
      2px 0px 0px 0px #{$color-secondary};
  }
}
