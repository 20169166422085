@import 'styles/variables';

.wrapper {
  width: 700px;

  .content {
    padding-bottom: 12px;
    max-height: 600px;
    overflow: auto;
  }

  .header {
  }
}
