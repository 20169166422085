$nav-aside-max: 256px;
$nav-aside-min: 100px;

$taxpayer-aside-width: 252px;
$taxpayer-header-height: 60px;

// z-indexes:
$z-header: 60;
$z-header-title: 70;
$z-letterTabs: 100;
$z-errorPanel: 100;
$z-loaderOverlay: 150;
$z-profileSelect: 170;
$z-aside: 200;
$z-navToggle: 250;
$z-modalOverlay: 500;
$z-tooltip: 600;
